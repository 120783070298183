// 2019-10-27 15:24:57
import React, { useState } from "react";
// import { useRef } from "react";
// import Prism from "prismjs";

import Layout from "../components/layout";
import SEO from "../components/seo";
import styled from "@emotion/styled";

const HighlightMe = styled.span`
  text-decoration: underline;
  ::selection {
    background: rgba(0, 0, 0, 0.01);
  }
`;

const TryMe = styled.span`
  ::selection {
    background: rgba(0, 0, 0, 0.01);
  }
`;

const Highlight = () => {
  const [input, setInput] = useState("");
  const target = "5361648437573754279180";
  return (
    <Layout>
      <SEO title="Highlight"></SEO>
      <p>Copy/paste the underlined text below into the text box:</p>
      <p>
        <TryMe>
          823846280015256167551974745255598294355
          <HighlightMe>{target}</HighlightMe>
          93653852787331868859837748881205374131595068005147685544974313774734952937
        </TryMe>
      </p>
      <p>
        <input
          type="text"
          value={input}
          onChange={e => setInput(e.target.value)}
        ></input>
      </p>
      {input && (
        <p>
          {input === target && (
            <>
              Nice! <a href="/">Home</a>
            </>
          )}
          {input !== target && <>Nope, that's not it</>}
        </p>
      )}
    </Layout>
  );
};

export default Highlight;
